import React, { useState } from "react";
import { useSnackbar } from 'notistack';
import firebaseDB from '../../firebase';
import "./ContactUs.css";

function ContactUs() {
  const { enqueueSnackbar } = useSnackbar();
  const [state, setState] = useState({
    name: "",
    email: "",
    subject: "",
    message: "",
  });

  const { name, email, subject, message } = state;

  const handleSubmit = (e) => {
    e.preventDefault();
    if (!name || !email || !subject || !message) {
      enqueueSnackbar("Please provide value in each input field", { variant: 'error' });
    } else {
      firebaseDB.child("contacts").push(state);
      setState({ name: "", email: "", subject: "", message: "" });
      enqueueSnackbar("Form Submitted Successfully", { variant: 'success' });
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setState({ ...state, [name]: value });
  };

  return (
    <section className="contact-section">
      <div className="container">
            <div className="wrapper">
           
                <div className="col-md-6">
                  <div className="contact-wrap w-100 p-lg-5 p-4">
                    <h3 className="mb-4">Send us a message</h3>
                    <form
                      id="contactForm"
                      className="contactForm"
                      onSubmit={handleSubmit}
                    >
                      <div className="row">
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="name"
                              placeholder="Name"
                              onChange={handleInputChange}
                              value={name}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="email"
                              className="form-control"
                              name="email"
                              placeholder="Email"
                              onChange={handleInputChange}
                              value={email}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="subject"
                              placeholder="Subject"
                              onChange={handleInputChange}
                              value={subject}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="text"
                              className="form-control"
                              name="message"
                              placeholder="Message"
                              // cols="30"
                              // rows="6"
                              onChange={handleInputChange}
                              value={message}
                            />
                          </div>
                        </div>
                        <div className="col-md-12">
                          <div className="form-group">
                            <input
                              type="submit"
                              value="Send Message"
                              className="btncontact btncontact-primary"
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
                <div className="contact-info">
  <div className="info-wrap-c">
    <h3>Contact us</h3>
    <p className="description-c">
      We're open for any suggestion or just to have a chat
    </p>
    <div className="map-wrap">
            
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d435896.42370527645!2d76.33057894093739!3d31.40197890304459!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x39053f6d36e95435%3A0xdf4a00cf1fabaf65!2sBilaspur%2C%20Himachal%20Pradesh!5e0!3m2!1sen!2sin!4v1721338935078!5m2!1sen!2sin" 
              width="100%"
              height="460px"
              style={{ border: 0 }}
              allowFullScreen
              loading="lazy"
              title="Google Maps"
            ></iframe>
          </div>
  </div>
</div>
    
            </div>
       
   
      </div>
    </section>
  );
}

export default ContactUs;