import React, { useState, useEffect } from "react";
import Navbar from "./components/Navbar";
import Home from "./components/Home/Home";
import About from "./components/About/About";
import Personal from "./components/Personal";
import Footer from "./components/Footer";
import Overall from "./components/Overall";
import ProductPage from "./components/Products/ProductPage";
import ContactUs from "./components/Contact/ContactUs";
import Marquee from "./components/marquee";
import {
  BrowserRouter as Router,
  Route,
  Routes,
  Navigate,
} from "react-router-dom";
import ScrollToTop from "./components/ScrollToTop";
import "./style.css";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import ProfileDashboard from "./components/Profile/ProfileDashboard";
import { AuthProvider } from "./contexts/AuthContext";
import PrivateRoute from "./components/PrivateRoute.js";
import ServiceProfile from "./components/ServiceProvider/ServiceProfile.js";
import SecondaryNavbar from "./components/SecondaryNavbar";
import Particularservice from "./components/particularservice.js";
import { ServiceProviderProvider } from "./contexts/ServiceProviderContext.js";
import TermsAndConditions from "./components/TermsAndConditions.js";
import PrivacyPolicy from "./components/PrivacyPolicy.js";
import ReturnPolicy from "./components/ReturnPolicy.js";
function App() {
  const [load, updateLoad] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      updateLoad(false);
    }, 1200);

    return () => clearTimeout(timer);
  }, []);

  return (
    <Router>
      <AuthProvider>
        <ServiceProviderProvider>
          <div className="App" id={load ? "no-scroll" : "scroll"}>
            <Marquee />
            <Navbar />
            <SecondaryNavbar />
            <ScrollToTop />
            <Routes>
              <Route path="/" element={<Home />} />
              <Route path="/about" element={<About />} />
              <Route path="/personal" element={<Personal />} />
              <Route path="/overall-services" element={<Overall />} />
              <Route
                path="/termsandconditions"
                element={<TermsAndConditions />}
              />
              <Route path="/privacypolicy" element={<PrivacyPolicy />} />
              <Route path="/returnpolicy" element={<ReturnPolicy />} />
              <Route
                path="/particular-service"
                element={<Particularservice />}
              />
              <Route path="/product-page" element={<ProductPage />} />
              <Route path="/contact" element={<ContactUs />} />
              <Route
                path="/user-profile"
                element={
                  <PrivateRoute>
                    <ProfileDashboard />
                  </PrivateRoute>
                }
              />
              <Route path="/service-profile" element={<ServiceProfile />} />
              {/* Redirect all unknown routes to Home */}
              <Route path="*" element={<Navigate to="/" />} />
            </Routes>
            <Footer />
          </div>
        </ServiceProviderProvider>
      </AuthProvider>
    </Router>
  );
}

export default App;
