import React, { useState, useEffect } from "react";
import "./auth-modal.css";
import { auth, getCurrentUser, firebaseDB } from "../firebase";
import { AiFillPhone, AiFillMail } from "react-icons/ai";
import GoogleLogo from "../Assets/googlelogo.png";
import logo from "../Assets/RAPSAPP.png";
import signupImage from "../Assets/login.webp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import {
  GoogleAuthProvider,
  signInWithRedirect,
  getRedirectResult,
  RecaptchaVerifier,
  signInWithPhoneNumber,
} from "firebase/auth";
import "react-toastify/dist/ReactToastify.css";

function AuthModal({ show, handleClose, setUserName }) {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const [isPhoneLogin, setIsPhoneLogin] = useState(false);

  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };

  const toggleForm = () => {
    setIsLogin(!isLogin);
    setIsForgotPassword(false);
    setIsPhoneLogin(false);
  };

  const notifyError = (error) => {
    let message;
    switch (error.code) {
      case "auth/invalid-email":
        message = "The email address is not valid. Please enter a valid email.";
        break;
      case "auth/user-disabled":
        message =
          "This user account has been disabled. Please contact support.";
        break;
      case "auth/user-not-found":
        message = "No user found with this email address.";
        break;
      case "auth/wrong-password":
        message = "Incorrect password. Please try again.";
        break;
      case "auth/email-already-in-use":
        message =
          "This email is already registered. Please use a different email.";
        break;
      case "auth/weak-password":
        message =
          "Your password is too weak. Please choose a stronger password.";
        break;
      default:
        message = error.message;
    }

    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = document.getElementById("formLoginEmail").value;
    const password = document.getElementById("formLoginPassword").value;

    if (!email || !password) {
      notifyError({ message: "Please enter all fields" });
      return;
    }

    try {
      await auth.signInWithEmailAndPassword(email, password);
      const user = await getCurrentUser();
      if (user) {
        // Set user role as 'user' in the database
        await firebaseDB.ref("users/" + user.uid).update({
          role: "user",
        });
        setUserName(user.displayName || user.email);
        handleClose();
      }
    } catch (error) {
      console.error("Error signing in:", error.code, error.message);
      notifyError(error);
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    const name = document.getElementById("formSignupName").value;
    const email = document.getElementById("formSignupEmail").value;
    const password = document.getElementById("formSignupPassword").value;

    if (!name || !email || !password) {
      notifyError({ message: "All fields are mandatory" });
      return;
    }

    try {
      await auth.createUserWithEmailAndPassword(email, password);
      const user = await getCurrentUser();
      if (user) {
        await user.updateProfile({ displayName: name });
        await firebaseDB.ref("users/" + user.uid).set({
          uid: user.uid,
          name: name,
          email: email,
          role: "user", 
        });
        setUserName(name);
        handleClose();
      }
    } catch (error) {
      console.error("Error signing up:", error.message);
      notifyError(error);
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    const email = document.getElementById("forgotPasswordEmail").value;

    if (!email) {
      notifyError({ message: "Email is required" });
      return;
    }

    try {
      await auth.sendPasswordResetEmail(email);
      toast.success("Password reset email sent", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
      });
      setIsForgotPassword(false);
    } catch (error) {
      console.error("Error sending password reset email:", error.message);
      notifyError(error);
    }
  };

  const handleGoogleSignIn = () => {
    const provider = new GoogleAuthProvider();
    signInWithRedirect(auth, provider);
  };

  const handlePhoneSignIn = () => {
    setIsPhoneLogin(true);
    setIsLogin(false);
  };

  const handleSendOtp = async (event) => {
    event.preventDefault();
    const phoneNumber = document.getElementById("formLoginPhone").value;

    if (!phoneNumber) {
      notifyError({ message: "Phone number is required" });
      return;
    }

    try {
      window.recaptchaVerifier = new RecaptchaVerifier(
        "recaptcha-container",
        {
          size: "invisible",
        },
        auth
      );

      const appVerifier = window.recaptchaVerifier;

      await signInWithPhoneNumber(auth, phoneNumber, appVerifier);
      toast.success("OTP sent");
      // Optionally, handle OTP verification here or switch view
    } catch (error) {
      console.error("Error sending OTP:", error.message);
      notifyError(error);
    }
  };

  useEffect(() => {
    if (show) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    getRedirectResult(auth)
      .then((result) => {
        if (result) {
          const user = result.user;
          setUserName(user.displayName || user.email);
          handleClose();
        }
      })
      .catch((error) => {
        console.error("Error during Google sign-in:", error.message);
        notifyError(error);
      });
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <ToastContainer />
      <div className="auth-modal-overlay" onClick={handleClose}>
        <div className="auth-modal" onClick={(e) => e.stopPropagation()}>
          <div className="auth-modal-body">
            <div className="auth-modal-row">
              <div className="auth-image-col">
                <img src={signupImage} className="auth-image" alt="Signup" />
              </div>
              <div className="auth-form-col">
                <img src={logo} className="auth-logo" alt="Logo" />
                <h3>
                  {isPhoneLogin
                    ? "Phone Sign In"
                    : isForgotPassword
                    ? "Reset your password"
                    : isLogin
                    ? "Welcome back! Glad to see you, Again!"
                    : "Create an account to get started !"}
                </h3>
                {isPhoneLogin ? (
                  <form onSubmit={handleSendOtp}>
                    <div className="form-group">
                      <input
                        type="phone"
                        id="formLoginPhone"
                        placeholder="Enter phone number"
                      />
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Send OTP
                    </button>
                    <div id="recaptcha-container"></div>
                  </form>
                ) : isForgotPassword ? (
                  <form
                    style={{ width: "100%" }}
                    onSubmit={handleForgotPassword}
                  >
                    <div className="form-group">
                      <input
                        type="email"
                        id="forgotPasswordEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Send Reset Link
                    </button>
                    <p
                      className="mt-3"
                      style={{ textAlign: "left", width: "100%" }}
                    >
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        Back to Login
                      </span>
                    </p>
                  </form>
                ) : (
                  <form
                    style={{ width: "100%" }}
                    onSubmit={isLogin ? handleLogin : handleSignup}
                  >
                    {isLogin ? (
                      <>
                        <div className="form-group">
                          <input
                            type="email"
                            id="formLoginEmail"
                            placeholder="Enter email"
                          />
                        </div>
                        <div className="password-input-container">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="formLoginPassword"
                            placeholder="Password"
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <button type="submit" className="btn-primary-nav">
                          Login
                        </button>
                        <p
                          className="mt-3"
                          style={{ textAlign: "left", width: "100%" }}
                        >
                          Forgot Password?
                          <span
                            className="toggle-form"
                            onClick={() => setIsForgotPassword(true)}
                            style={{ marginLeft: "5px" }}
                          >
                            Click to Reset
                          </span>
                        </p>
                        {/* <p
                          className="mt-3"
                          style={{ textAlign: "left", width: "100%" }}
                        >
                          <span
                            className="toggle-form"
                            onClick={handlePhoneSignIn}
                            style={{ marginLeft: "5px" }}
                          >
                            Use Phone Number
                          </span>
                        </p> */}
                      </>
                    ) : (
                      <>
                        <div className="form-group">
                          <input
                            type="text"
                            id="formSignupName"
                            placeholder="Enter full name"
                          />
                        </div>
                        <div className="form-group">
                          <input
                            type="email"
                            id="formSignupEmail"
                            placeholder="Enter email"
                          />
                        </div>
                        <div className="password-input-container">
                          <input
                            type={showPassword ? "text" : "password"}
                            id="formSignupPassword"
                            placeholder="Password"
                          />
                          <span
                            className="password-toggle-icon"
                            onClick={togglePasswordVisibility}
                          >
                            {showPassword ? <FaEyeSlash /> : <FaEye />}
                          </span>
                        </div>
                        <button type="submit" className="btn-primary-nav">
                          Sign Up
                        </button>
                      </>
                    )}
                  </form>
                )}
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    width: "100%",
                    justifyContent: "space-between",
                    alignItems: "center",
                  }}
                >
                  <hr
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "black",
                      margin: "0 10px",
                    }}
                  />
                  <p className="text-center mt-3">Or Sign Up With</p>
                  <hr
                    style={{
                      flex: 1,
                      height: "1px",
                      backgroundColor: "black",
                      margin: "0 10px",
                    }}
                  />
                </div>
                <div className="social-icons">
                  {isPhoneLogin ? (
                    <>
                      <div
                        style={{
                          border: "0.5px solid #E8ECF4",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={handleGoogleSignIn}
                      >
                        <img
                          src={GoogleLogo}
                          alt="Google Logo"
                          style={{ width: "32px" }}
                          className="social-icon"
                        />
                      </div>
                      <div
                        style={{
                          border: "0.5px solid #E8ECF4",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={() => setIsPhoneLogin(false)}
                      >
                        <AiFillMail className="social-icon" />
                      </div>
                    </>
                  ) : (
                    <>
                      <div
                        style={{
                          border: "0.5px solid #E8ECF4",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={handlePhoneSignIn}
                      >
                        <AiFillPhone className="social-icon" />
                      </div>
                      <div
                        style={{
                          border: "0.5px solid #E8ECF4",
                          borderRadius: "4px",
                          padding: "8px 12px",
                          cursor: "pointer",
                        }}
                        onClick={handleGoogleSignIn}
                      >
                        <img
                          src={GoogleLogo}
                          alt="Google Logo"
                          style={{ width: "32px" }}
                          className="social-icon"
                        />
                      </div>
                    </>
                  )}
                </div>
                <p className="text-center mt-3">
                  {isLogin ? "New User?" : "Already have an account?"}{" "}
                  <span className="toggle-form" onClick={toggleForm}>
                    {isLogin ? "Sign Up" : "Sign In"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default AuthModal;
