import React from "react";
import "./pagetemplate.css";
function ReturnPolicy() {
  return (
    <div className="terms-container">
      <h2>Refund and Cancellation Policy</h2>

      <p>
        This refund and cancellation policy outlines how you can cancel or seek
        a refund for a product/service that you have purchased through the
        Platform. Under this policy:
      </p>

      <h3>Cancellations</h3>
      <p>
        Cancellations will only be considered if the request is made within{" "}
        <strong>5 days</strong> of placing the order. However, cancellation
        requests may not be entertained if:
      </p>
      <ul>
        <li>
          <strong>
            The orders have been communicated to sellers/merchants listed on the
            Platform and they have initiated the process of shipping them
          </strong>
        </li>
        <li>
          <strong>The product is out for delivery</strong>
        </li>
      </ul>
      <p>
        In such an event, you may choose to reject the product at the doorstep.
      </p>

    

      <h3>Damaged or Defective Items</h3>
      <p>
        In case of receipt of damaged or defective items, please report to our
        customer service team. The request will be entertained once the
        seller/merchant listed on the Platform has checked and determined the
        issue at its own end. This should be reported within{" "}
        <strong>5 days</strong> of receipt of the products.
      </p>

      <h3>Product Not as Expected</h3>
      <p>
        If you feel that the product received is not as shown on the site or as
        per your expectations, you must bring it to the notice of our customer
        service within <strong>5 days</strong> of receiving the product. The
        customer service team will review your complaint and take an appropriate
        decision.
      </p>

      <h3>Products with Warranty</h3>
      <p>
        For complaints regarding products that come with a warranty from the
        manufacturers, please refer to the issue directly with them.
      </p>

      <h3>Refund Processing</h3>
      <p>
        In case of any refunds approved by RapsApp Private Limited, it will take{" "}
        <strong>5 days</strong> for the refund to be processed to you.
      </p>
    </div>
  );
}

export default ReturnPolicy;
