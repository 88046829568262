import React from "react";
import "./pagetemplate.css";
function PrivacyPolicy() {
  return (
    <div className="terms-container">
      <h2>Privacy Policy</h2>

      <h3>Introduction</h3>
      <p>
        This Privacy Policy describes how RapsApp Private Limited and its
        affiliates (collectively "RapsApp Private Limited, we, our, us")
        collect, use, share, protect, or otherwise process your
        information/personal data through our website{" "}
        <a href="http://www.rapsapp.in">www.rapsapp.in</a> (hereinafter referred
        to as Platform). Please note that:
      </p>
      <ul>
        <li>
          <strong>
            You may browse certain sections of the Platform without registering.
          </strong>
        </li>
        <li>
          <strong>
            We do not offer any product/service outside India, and your personal
            data will primarily be stored and processed in India.
          </strong>
        </li>
      </ul>
      <p>
        By visiting this Platform, providing your information, or availing any
        product/service, you agree to be bound by:
      </p>
      <ul>
        <li>
          <strong>The terms and conditions of this Privacy Policy</strong>
        </li>
        <li>
          <strong>The Terms of Use</strong>
        </li>
        <li>
          <strong>Applicable service/product terms and conditions</strong>
        </li>
        <li>
          <strong>
            Laws of India, including data protection and privacy laws
          </strong>
        </li>
      </ul>
      <p>
        <strong>
          If you do not agree, please do not use or access our Platform.
        </strong>
      </p>

      <h3>Collection</h3>
      <p>We collect your personal data when you use our Platform, including:</p>
      <ul>
        <li>
          <strong>Personal Information:</strong> Name, date of birth, address,
          telephone/mobile number, email ID, and any proof of identity or
          address.
        </li>
        <li>
          <strong>Sensitive Personal Data:</strong> Bank account or credit/debit
          card information, biometric information (e.g., facial features), etc.,
          with your consent.
        </li>
        <li>
          <strong>Behavioral Data:</strong> Your behavior, preferences, and
          information provided on our Platform.
        </li>
        <li>
          <strong>Transaction Data:</strong> Information related to transactions
          on our Platform and third-party business partner platforms.
        </li>
      </ul>
      <p>
        <strong>Note:</strong> If a third-party business partner collects your
        data, their privacy policies apply, and we are not responsible for their
        practices.
      </p>

      <h3>Usage</h3>
      <p>We use personal data to:</p>
      <ul>
        <li>
          <strong>Provide requested services</strong>
        </li>
        <li>
          <strong>Market to you (with the option to opt-out)</strong>
        </li>
        <li>
          <strong>
            Assist in handling orders, enhance customer experience, resolve
            disputes, and troubleshoot problems
          </strong>
        </li>
        <li>
          <strong>
            Inform you about offers, products, services, and updates
          </strong>
        </li>
        <li>
          <strong>
            Customize your experience and detect/protect against fraud and other
            criminal activities
          </strong>
        </li>
      </ul>

      <h3>Sharing</h3>
      <p>
        We may share your personal data internally within our group entities,
        and with:
      </p>
      <ul>
        <li>
          <strong>
            Third parties such as sellers, business partners, third-party
            service providers, and payment instrument issuers
          </strong>
        </li>
        <li>
          <strong>
            Government agencies or law enforcement if required by law
          </strong>
        </li>
        <li>
          <strong>
            Law enforcement offices, third party rights owners, or others to
            protect rights, property, or safety
          </strong>
        </li>
      </ul>

      <h3>Security Precautions</h3>
      <p>
        To protect your personal data from unauthorized access or disclosure,
        loss, or misuse, we:
      </p>
      <ul>
        <li>
          <strong>Adopt reasonable security practices and procedures</strong>
        </li>
        <li>
          <strong>Adhere to security guidelines to protect your data</strong>
        </li>
        <li>
          <strong>
            Note that data transmission over the internet is not completely
            secure
          </strong>
        </li>
      </ul>

      <h3>Data Deletion and Retention</h3>
      <p>
        You have the option to delete your account, which will result in losing
        all information related to your account. We may retain your personal
        data for:
      </p>
      <ul>
        <li>
          <strong>
            As long as necessary for the purpose for which it was collected or
            as required by law
          </strong>
        </li>
        <li>
          <strong>
            Preventing fraud, abuse, or for other legitimate purposes
          </strong>
        </li>
        <li>
          <strong>Anonymized form for analytical and research purposes</strong>
        </li>
      </ul>

      <h3>Your Rights</h3>
      <p>
        You may access, rectify, and update your personal data directly through
        the Platform's functionalities.
      </p>

      <h3>Consent</h3>
      <p>
        By visiting our Platform or providing your information, you consent to
        the collection, use, storage, disclosure, and processing of your
        information as described in this Privacy Policy.
      </p>

      <h3>Changes to this Privacy Policy</h3>
      <p>
        Please check our Privacy Policy periodically for updates. We may notify
        you of significant changes as required by law.
      </p>

      <h3>Grievance Officer</h3>
      <p>
        <strong>Insert Name of the Officer:</strong>
        <br />
        <strong>Designation:</strong>
        <br />
        <strong>Insert Name and Address of the Company:</strong>
        <br />
        <strong>Contact us:</strong>
        <br />
        <strong>Phone:</strong> Time: Monday - Friday (9:00 - 18:00)
      </p>
    </div>
  );
}

export default PrivacyPolicy;
