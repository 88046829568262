import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import servicesData from "./serviceData.json";
import "./pesonal.css";
import servicebg from "../Assets/servicetype1.webp";
import reviews from "../Assets/reviews.png";
import { useNavigate } from "react-router-dom";
function Personal() {
  const location = useLocation();
  const selectedService = location.state?.selectedService || {};
  const [selectedCategory, setSelectedCategory] = useState(selectedService);
  const [filteredServices, setFilteredServices] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    const category = servicesData.categories
      .flatMap((category) => category.subcategories)
      .find((subcategory) => subcategory.name === selectedCategory);
    if (category) {
      setFilteredServices(category.services);
    }
  }, [selectedCategory]);

  return (
    <div>
      <div
        className="ps-header"
        style={{ paddingTop: "150px", width: "78%", margin: "0 auto" }}
      >
        <div className="ps-head">
          <h2>{selectedCategory} Services</h2>
          <p>Your Go-To Platform for Prioritized Services</p>
        </div>
        <div>
          <button
            style={{
              padding: "10px",
              color: "white",
              background: "black",
              borderRadius: "10px",
              fontSize: "18px",
            }}
          >
            Sort By
          </button>
        </div>
      </div>

      <section
        className="home-services"
        style={{ width: "80%", margin: "40px auto 80px auto" }}
      >
        <section className="service-container">
          <div className="service-left-panel">
            <div className="service-subcategories">
              <h3>Subcategories</h3>
              <hr />
              <ul>
                {servicesData.categories
                  .flatMap((category) => category.subcategories)
                  .map((subcategory) => (
                    <li
                      key={subcategory.name}
                      onClick={() => setSelectedCategory(subcategory.name)}
                      style={{
                        cursor: "pointer",
                        fontWeight:
                          subcategory.name === selectedCategory
                            ? "bold"
                            : "normal",
                      }}
                    >
                      {subcategory.name}
                    </li>
                  ))}
              </ul>
            </div>
          </div>

          <div className="service-right-panel">
            {filteredServices.length > 0 ? (
              filteredServices.map((service, index) => (
                <div
                  className="service-card"
                  key={index}
                  style={{ backgroundImage: `url(${servicebg})` }}
                >
                  <div className="service-card-content">
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "column",
                        justifyContent: "end",
                        width: "100%",
                      }}
                    >
                      <div
                        style={{
                          display: "flex",
                          flexDirection: "row",
                          width: "100%",
                        }}
                      >
                        <div className="service-card-text">
                          <h4>{service.title}</h4>
                          <p style={{ fontSize: "22px", marginBottom: "0px" }}>
                            {service.description}
                          </p>
                          <p style={{ fontSize: "20px" }}>
                            {service.description2}
                          </p>
                        </div>
                        <img
                          src={reviews}
                          style={{ width: "150px", height: "130px" }}
                          alt="Review"
                          className="service-card-review-image"
                        />
                      </div>
                      <div className="btns-group">
                        <button
                          className="btns1"
                          onClick={navigate("/product-page")}
                        >
                          {service.btn1Text}
                        </button>
                        <button className="btns2">{service.btn2Text}</button>
                      </div>
                    </div>
                  </div>
                </div>
              ))
            ) : (
              <p>No services available.</p>
            )}
          </div>
        </section>
      </section>
    </div>
  );
}

export default Personal;
