import React, { useState, useEffect } from "react";
import { auth, firebaseDB } from "../firebase"; 
import { 
  createUserWithEmailAndPassword, 
  signInWithEmailAndPassword, 
  sendPasswordResetEmail, 
  signInWithPopup, 
  GoogleAuthProvider 
} from "firebase/auth";
import { ref, set } from "firebase/database";
import GoogleLogo from "../Assets/googlelogo.png";
import logo from "../Assets/RAPSAPP.png";
import signupImage from "../Assets/login.webp";
import { FaEye, FaEyeSlash } from "react-icons/fa";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useServiceProvider } from "../contexts/ServiceProviderContext";
import "./service-provider-auth.css";

function ServiceProviderAuth({ show, handleClose }) {
  const [isLogin, setIsLogin] = useState(true);
  const [showPassword, setShowPassword] = useState(false);
  const [isForgotPassword, setIsForgotPassword] = useState(false);
  const { setServiceProvider } = useServiceProvider();

  const togglePasswordVisibility = () => {
    setShowPassword(prev => !prev);
  };

  const handleFormSwitch = () => {
    setIsLogin(prev => !prev);
    setIsForgotPassword(false);
  };

  const notifyError = (message) => {
    toast.error(message, {
      position: "top-right",
      autoClose: 5000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
    });
  };

  const saveServiceProviderToDatabase = async (uid, name, email) => {
    try {
      await set(ref(firebaseDB, `serviceproviders/${uid}`), {
        uid,
        name,
        email,
        role: "service-provider", // Assign role
      });
    } catch (error) {
      console.error("Failed to save user data:", error);
      notifyError("Failed to save user data.");
    }
  };

  const handleLogin = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerLoginEmail").value;
    const password = document.getElementById("providerLoginPassword").value;

    if (!email || !password) {
      notifyError("Email and password are required.");
      return;
    }

    try {
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setServiceProvider({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      });
      window.location.href = `/provider-dashboard?uid=${user.uid}`;
    } catch (error) {
      notifyError(error.message || "Login failed. Please try again.");
    }
  };

  const handleSignup = async (event) => {
    event.preventDefault();
    const name = document.getElementById("providerSignupName").value;
    const email = document.getElementById("providerSignupEmail").value;
    const password = document.getElementById("providerSignupPassword").value;

    if (!name || !email || !password) {
      notifyError("All fields are mandatory.");
      return;
    }

    try {
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      setServiceProvider({
        uid: user.uid,
        email: user.email,
        displayName: name,
      });
      await saveServiceProviderToDatabase(user.uid, name, email);
      window.location.href = `/provider-dashboard?uid=${user.uid}`;
    } catch (error) {
      notifyError(error.message || "Sign up failed. Please try again.");
    }
  };

  const handleForgotPassword = async (event) => {
    event.preventDefault();
    const email = document.getElementById("providerForgotPasswordEmail").value;

    if (!email) {
      notifyError("Email is required.");
      return;
    }

    try {
      await sendPasswordResetEmail(auth, email);
      toast.success("Password reset email sent.");
      setIsForgotPassword(false);
    } catch (error) {
      notifyError(error.message || "Failed to send reset email.");
    }
  };

  const handleGoogleSignIn = async () => {
    const provider = new GoogleAuthProvider();
    try {
      const result = await signInWithPopup(auth, provider);
      const user = result.user;
      setServiceProvider({
        uid: user.uid,
        email: user.email,
        displayName: user.displayName,
      });
      await saveServiceProviderToDatabase(user.uid, user.displayName, user.email);
      window.location.href = `/provider-dashboard?uid=${user.uid}`;
    } catch (error) {
      notifyError(error.message || "Google login failed.");
    }
  };

  useEffect(() => {
    document.body.style.overflow = show ? "hidden" : "auto";
  }, [show]);

  if (!show) {
    return null;
  }

  return (
    <>
      <ToastContainer />
      <div className="service-provider-auth-overlay" onClick={handleClose}>
        <div
          className="service-provider-auth-modal"
          onClick={(e) => e.stopPropagation()}
        >
          <div className="service-provider-auth-body">
            <div className="service-provider-auth-row">
              <div className="service-provider-auth-image-col">
                <img
                  src={signupImage}
                  className="service-provider-auth-image"
                  alt="Signup"
                />
              </div>
              <div className="service-provider-auth-form-col">
                <img
                  src={logo}
                  className="service-provider-auth-logo"
                  alt="Logo"
                />
                <h3>
                  {isLogin
                    ? "Welcome back! Glad to see you, Again!"
                    : "Complete your registration"}
                </h3>
                {isForgotPassword ? (
                  <form style={{ width: "100%" }} onSubmit={handleForgotPassword}>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerForgotPasswordEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Send Reset Link
                    </button>
                    <p className="mt-3">
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(false)}
                      >
                        Back to Login
                      </span>
                    </p>
                  </form>
                ) : isLogin ? (
                  <form style={{ width: "100%" }} onSubmit={handleLogin}>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerLoginEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerLoginPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Login
                    </button>
                    <p className="mt-3">
                      Forgot Password?{" "}
                      <span
                        className="toggle-form"
                        onClick={() => setIsForgotPassword(true)}
                      >
                        Click to Reset
                      </span>
                    </p>
                  </form>
                ) : (
                  <form style={{ width: "100%" }} onSubmit={handleSignup}>
                    <div className="form-group">
                      <input
                        type="text"
                        id="providerSignupName"
                        placeholder="Enter full name"
                      />
                    </div>
                    <div className="form-group">
                      <input
                        type="email"
                        id="providerSignupEmail"
                        placeholder="Enter email"
                      />
                    </div>
                    <div className="password-input-container">
                      <input
                        type={showPassword ? "text" : "password"}
                        id="providerSignupPassword"
                        placeholder="Password"
                      />
                      <span
                        className="password-toggle-icon"
                        onClick={togglePasswordVisibility}
                      >
                        {showPassword ? <FaEyeSlash /> : <FaEye />}
                      </span>
                    </div>
                    <button type="submit" className="btn-primary-nav">
                      Sign Up
                    </button>
                  </form>
                )}
                <div className="social-icons">
                  <div
                    style={{
                      border: "0.5px solid #E8ECF4",
                      borderRadius: "4px",
                      padding: "8px",
                      textAlign: "center",
                    }}
                    onClick={handleGoogleSignIn}
                  >
                    <img
                      src={GoogleLogo}
                      alt="Google"
                      style={{ width: "20px", marginRight: "5px" }}
                    />
                    Sign in with Google
                  </div>
                </div>
                <p className="mt-3">
                  {isLogin
                    ? "Don't have an account? "
                    : "Already have an account? "}
                  <span className="toggle-form" onClick={handleFormSwitch}>
                    {isLogin ? "Sign Up" : "Login"}
                  </span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ServiceProviderAuth;
