import React, { useState, useEffect } from "react";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import Container from "react-bootstrap/Container";
import { Link, useLocation } from "react-router-dom";
import { AiOutlineSearch } from "react-icons/ai";
import { MdLocationOn } from "react-icons/md";
import rapsapp from "../Assets/RAPSAPP2.png";
import "./navbar.css";
import { FaUser } from "react-icons/fa";
import AuthModal from "./AuthModal";
import { auth } from "../firebase";
import firebase from "firebase/compat/app"; // Ensure firebase is imported correctly

function NavBar() {
  const [expand, updateExpanded] = useState(false);
  const [navColour, updateNavbar] = useState(false);
  const [showAuthModal, setShowAuthModal] = useState(false);
  const [userName, setUserName] = useState("");
  const [currentCity, setCurrentCity] = useState("");
  const [toggleDropdownVisible, setToggleDropdownVisible] = useState(false);
  const [servicesDropdownVisible, setServicesDropdownVisible] = useState(false);
  const location = useLocation(); // Get the current location

  const handleAuthModalClose = () => setShowAuthModal(false);

  const handleAuthModalShow = () => {
    window.scrollTo({ top: 0, behavior: "smooth" });
    setShowAuthModal(true);
  };

  const scrollHandler = () => {
    if (window.scrollY >= 20) {
      updateNavbar(true);
    } else {
      updateNavbar(false);
    }
  };

  const fetchCityName = async () => {
    try {
      const response = await fetch(`https://ipinfo.io/json?token=6804cf228e70dd`);
      const data = await response.json();
      if (data.city) {
        setCurrentCity(data.city);
      } else {
        setCurrentCity("Unknown Location");
      }
    } catch (error) {
      console.error("Error fetching city name:", error);
      setCurrentCity("Error fetching location");
    }
  };

  const getLocation = () => {
    if (navigator.geolocation) {
      navigator.geolocation.getCurrentPosition(
        (position) => {
          const { latitude, longitude } = position.coords;
          fetchCityName(latitude, longitude);
        },
        (error) => {
          console.error("Error getting location:", error);
        }
      );
    } else {
      console.error("Geolocation is not supported by this browser.");
    }
  };

  const handleLogout = async () => {
    try {
      await auth.signOut();
      setUserName("");
    } catch (error) {
      console.error("Error logging out:", error);
    }
  };

  useEffect(() => {
    const unsubscribe = auth.onAuthStateChanged((user) => {
      if (user) {
        const uid = user.uid;

        // Reference to users and service providers in Firebase
        const userRef = firebase.database().ref(`users/${uid}`);
        const serviceProviderRef = firebase.database().ref(`serviceproviders/${uid}`);

        // Check if user is under 'users' with role 'user'
        userRef.once("value", (snapshot) => {
          const userData = snapshot.val();
          if (userData && userData.role === "user") {
            setUserName(user.displayName || "User");
          } else {
            // If not found under 'users', check under 'serviceproviders'
            serviceProviderRef.once("value", (snapshot) => {
              const serviceProviderData = snapshot.val();
              if (serviceProviderData && serviceProviderData.role === "user") {
                setUserName(user.displayName || "User");
              } else {
                setUserName(""); // Reset if no user role is found in either category
              }
            });
          }
        });
      } else {
        setUserName(""); // Reset if no user is logged in
      }
    });

    getLocation(); // Fetch current city on mount
    window.addEventListener("scroll", scrollHandler);

    return () => {
      window.removeEventListener("scroll", scrollHandler);
      unsubscribe(); // Cleanup auth state listener
    };
  }, []);

  const handleToggleClick = () => {
    updateExpanded(!expand);
    setToggleDropdownVisible(!toggleDropdownVisible);
  };

  const handleServicesMouseEnter = () => {
    setServicesDropdownVisible(true);
  };

  const handleServicesMouseLeave = () => {
    setServicesDropdownVisible(false);
  };

  const isActive = (path) => location.pathname === path; // Check if the path matches the current location

  return (
    <Navbar
      expanded={expand}
      fixed="top"
      expand="custom"
      className={
        navColour ? "sticky navbar-expand-custom" : "navbar navbar-expand-custom"
      }
    >
      <Container className="custom-container">
        <Navbar.Brand href="/" className="d-flex">
          <img src={rapsapp} className="img-fluid logo" alt="brand" />
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="responsive-navbar-nav" onClick={handleToggleClick}>
          <div className="toggle-icon">
            <span></span>
            <span></span>
            <span></span>
          </div>
          {toggleDropdownVisible && (
            <div className="dropdown-menu-nav">
              <Link className="dropdown-item" to="/home" onClick={() => setToggleDropdownVisible(false)}>
                Home
              </Link>
              <Link className="dropdown-item" to="/overall-services" onClick={() => setToggleDropdownVisible(false)}>
                Services
              </Link>
              <Link className="dropdown-item" to="/about" onClick={() => setToggleDropdownVisible(false)}>
                About Us
              </Link>
              <Link className="dropdown-item" to="/contact" onClick={() => setToggleDropdownVisible(false)}>
                Contact Us
              </Link>
              {userName ? (
                <>
                  <Link className="dropdown-item" to="/user-profile" onClick={() => setToggleDropdownVisible(false)}>
                    {userName}
                  </Link>
                  <span className="dropdown-item" onClick={handleLogout}>
                    Logout
                  </span>
                </>
              ) : (
                <button className="nav-link login-button" onClick={handleAuthModalShow}>
                  Login/Signup
                </button>
              )}
            </div>
          )}
        </Navbar.Toggle>

        <Navbar.Collapse id="responsive-navbar-nav">
          <Nav className="mr-auto">
            <Nav className="nav-mid" defaultActiveKey="#home">
              <Nav.Item className={`nav-item ${isActive("/") ? "nav-active" : ""}`}>
                <Nav.Link as={Link} to="/" onClick={() => updateExpanded(false)}>
                  Home
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${isActive("/overall-services") ? "nav-active" : ""}`}>
                <Nav.Link as={Link} to="/overall-services" onMouseEnter={handleServicesMouseEnter} onMouseLeave={handleServicesMouseLeave}>
                  Services
                </Nav.Link>
                {servicesDropdownVisible && (
                  <div className="dropdown-menu">
                    <Link className="dropdown-item" to="/service1" onClick={() => setServicesDropdownVisible(false)}>
                      Service 1
                    </Link>
                    <Link className="dropdown-item" to="/service2" onClick={() => setServicesDropdownVisible(false)}>
                      Service 2
                    </Link>
                    <Link className="dropdown-item" to="/service3" onClick={() => setServicesDropdownVisible(false)}>
                      Service 3
                    </Link>
                    <Link className="dropdown-item" to="/service4" onClick={() => setServicesDropdownVisible(false)}>
                      Service 4
                    </Link>
                  </div>
                )}
              </Nav.Item>
              <Nav.Item className={`nav-item ${isActive("/about") ? "nav-active" : ""}`}>
                <Nav.Link as={Link} to="/about" onClick={() => updateExpanded(false)}>
                  About Us
                </Nav.Link>
              </Nav.Item>
              <Nav.Item className={`nav-item ${isActive("/contact") ? "nav-active" : ""}`}>
                <Nav.Link as={Link} to="/contact" onClick={() => updateExpanded(false)}>
                  Contact Us
                </Nav.Link>
              </Nav.Item>
            </Nav>
          </Nav>
          <Nav className="nav-end">
            <div className="search-bar">
              <AiOutlineSearch className="search-icon" />
              <input type="text" placeholder="Search" className="search-input" />
              <Nav.Link as={Link} style={{ paddingRight: "4px", marginInline: "8px" }} to="/location">
                <MdLocationOn style={{ marginBottom: "2px" }} />
                {currentCity ? currentCity : "Current Location"}
              </Nav.Link>
            </div>
            {userName ? (
              <>
                <div className="nav-link login-button">
                  <Link to="/user-profile" style={{ textDecoration: "none", color: "white" }}>
                    {userName}
                  </Link>
                </div>
              </>
            ) : (
              <button className="nav-link login-button" onClick={handleAuthModalShow}>
                <FaUser style={{ fontSize: "22px", marginBottom: "2px" }} />
                Login/Signup
              </button>
            )}
          </Nav>
        </Navbar.Collapse>
      </Container>
      <AuthModal show={showAuthModal} handleClose={handleAuthModalClose} />
    </Navbar>
  );
}

export default NavBar;
