import React, { useState, useEffect } from "react";
import {
  FaUser,
  FaCalendarAlt,
  FaLanguage,
  FaHistory,
  FaWallet,
  FaQuestionCircle,
  FaSignOutAlt,
} from "react-icons/fa";
import { useAuth } from "../../contexts/AuthContext";
import { firebaseDB } from "../../firebase";
import "./ServiceProfile.css";
import moment from "moment";
import Modal from "react-modal";
// import FAQSection from "./FAQSection.js";
import AddressPopup from "../AddressPopup.js";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase";

const ServiceProfile = () => {
  const [currentTab, setCurrentTab] = useState("userProfile");
  const { currentUser } = useAuth();
  const [userDetails, setUserDetails] = useState({
    fullName: "",
    phoneNumber: "",
    emailAddress: "",
    dateOfBirth: "",
    addressOne: "",
    addressTwo: "",
    addressThree: "",
    profilePic: "",
  });
  const [chosenDate, setChosenDate] = useState(moment().format("YYYY-MM-DD"));
  const [greetingMessage, setGreetingMessage] = useState("Good Morning,");
  const [bookingsList, setBookingsList] = useState({
    "2024-08-09": ["Booking 1 at 10:00 AM", "Booking 2 at 12:00 PM"],
    "2024-08-10": ["Booking 3 at 02:00 PM"],
  });
  const [isLanguageModalOpen, setIsLanguageModalOpen] = useState(false);
  const [hasUserChanges, setHasUserChanges] = useState(false);
  const [isAddressModalVisible, setIsAddressModalVisible] = useState(false);
  const [currentAddressField, setCurrentAddressField] = useState("");
  const bookingDetails = {
    "2024-08-29": [
      {
        serialNumber: 1,
        serviceName: "Service 1",
        providerName: "Provider A",
        date: "28 August",
        time: "10:00 AM",
        duration: "1 hrs",
        price: "50",
        location: "Location 1",
        status: "Confirmed",
      },
      {
        serialNumber: 2,
        serviceName: "Service 2",
        providerName: "Provider B",
        date: "28 August",
        time: "12:00 PM",
        duration: "2 hrs",
        price: "100",
        location: "Location 2",
        status: "Pending",
      },
    ],
    "2024-08-10": [
      {
        serialNumber: 1,
        serviceName: "Service 3",
        providerName: "Provider C",
        date: "2024-08-10",
        time: "02:00 PM",
        duration: "1.5 hours",
        price: "$75",
        location: "Location 3",
        status: "Completed",
      },
    ],
  };

  useEffect(() => {
    const updateGreetingMessage = () => {
      const hour = new Date().getHours();
      if (hour < 12) {
        setGreetingMessage("Good Morning,");
      } else if (hour < 18) {
        setGreetingMessage("Good Afternoon,");
      } else if (hour < 24) {
        setGreetingMessage("Good Evening,");
      } else {
        setGreetingMessage("Good Night,");
      }
    };

    updateGreetingMessage();
    const interval = setInterval(updateGreetingMessage, 60000);

    return () => clearInterval(interval);
  }, []);

  useEffect(() => {
    if (currentUser) {
      const userRef = firebaseDB.ref(`users/${currentUser.uid}`);
      userRef.on("value", (snapshot) => {
        const data = snapshot.val();
        if (data) {
          setUserDetails((prevState) => ({
            ...prevState,
            ...data,
            fullName: data.name || currentUser.displayName,
            emailAddress: data.email || currentUser.email,
          }));
        } else {
          setUserDetails((prevState) => ({
            ...prevState,
            fullName: currentUser.displayName,
            emailAddress: currentUser.email,
          }));
        }
      });

      return () => userRef.off();
    }
  }, [currentUser]);

  const handleInputChange = async (e) => {
    const { name, value } = e.target;
    setUserDetails((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setHasUserChanges(true);
  };

  const handleImageUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = async () => {
        const profilePic = reader.result;
        setUserDetails((prevState) => ({
          ...prevState,
          profilePic,
        }));
        setHasUserChanges(true);
      };
      reader.readAsDataURL(file);
    }
  };

  const handleSaveChanges = async () => {
    if (!userDetails.addressOne) {
      alert("Address 1 is required.");
      return;
    }

    if (currentUser) {
      const userRef = firebaseDB.ref(`users/${currentUser.uid}`);
      await userRef.update({
        ...userDetails,
        fullName: currentUser.displayName,
        emailAddress: currentUser.email,
      });
      alert("Profile updated successfully!");
      setHasUserChanges(false);
    }
  };

  const handleDateSelection = (date) => {
    setChosenDate(date);
  };

  const toggleLanguageModal = () => {
    setIsLanguageModalOpen(!isLanguageModalOpen);
  };

  const closeLanguageModal = () => {
    setIsLanguageModalOpen(false);
  };

  const openAddressModal = (field) => {
    setCurrentAddressField(field);
    setIsAddressModalVisible(true);
  };

  const closeAddressModal = () => {
    setIsAddressModalVisible(false);
  };

  const handleAddressSave = (fullAddress) => {
    setUserDetails((prevState) => ({
      ...prevState,
      [currentAddressField]: fullAddress,
    }));
    setHasUserChanges(true);
    setIsAddressModalVisible(false);
  };

  const handleLogoutClick = async () => {
    try {
      await signOut(auth);
      alert("Logged out successfully!");
    } catch (error) {
      console.error("Error logging out: ", error);
      alert("Error logging out. Please try again.");
    }
  };

  const renderTabContent = () => {
    switch (currentTab) {
      case "userProfile":
        return (
          <div className="user-profile-section">
            <h2>User Profile</h2>
            <h1>Basic Information</h1>
            <div className="user-profile-details">
              <div className="user-info">
                <div className="input-group">
                  <div className="info-row">
                    <div className="info-item">
                      <label>Name</label>
                      <input
                        type="text"
                        name="fullName"
                        value={userDetails.fullName}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                    <div className="info-item">
                      <label>Phone</label>
                      <input
                        type="text"
                        name="phoneNumber"
                        value={userDetails.phoneNumber}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="info-item">
                      <label>Email</label>
                      <input
                        type="email"
                        name="emailAddress"
                        value={userDetails.emailAddress}
                        onChange={handleInputChange}
                        disabled
                      />
                    </div>
                    <div className="info-item">
                      <label>D.O.B.</label>
                      <input
                        type="date"
                        name="dateOfBirth"
                        value={userDetails.dateOfBirth}
                        onChange={handleInputChange}
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div className="info-item image-uploader">
                      <div
                        className="image-display"
                        onClick={() =>
                          document.getElementById("imageUpload").click()
                        }
                      >
                        {userDetails.profilePic ? (
                          <img
                            src={userDetails.profilePic}
                            alt="Profile"
                            style={{
                              objectFit: "cover",
                            }}
                          />
                        ) : (
                          <div className="image-placeholder">Upload Image</div>
                        )}
                      </div>
                      <input
                        id="imageUpload"
                        type="file"
                        onChange={handleImageUpload}
                        style={{ display: "none" }}
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <h1 style={{ marginTop: "40px" }}>Address Information</h1>
            <div className="user-profile-details">
              <div className="user-info">
                <div className="input-group">
                  <div className="info-row">
                    <div
                      className="info-item address-field"
                      onClick={() => openAddressModal("addressOne")}
                    >
                      <label>Address 1 *</label>
                      <input
                        type="text"
                        name="addressOne"
                        value={userDetails.addressOne}
                        disabled
                        placeholder="Tap to Add Address 1"
                      />
                    </div>
                    <div
                      className="info-item address-field"
                      onClick={() => openAddressModal("addressTwo")}
                    >
                      <label>Address 2</label>
                      <input
                        type="text"
                        name="addressTwo"
                        value={userDetails.addressTwo}
                        disabled
                        placeholder="Tap to Add Address 2"
                      />
                    </div>
                  </div>
                  <div className="info-row">
                    <div
                      className="info-item address-field"
                      onClick={() => openAddressModal("addressThree")}
                    >
                      <label>Address 3</label>
                      <input
                        type="text"
                        name="addressThree"
                        value={userDetails.addressThree}
                        disabled
                        placeholder="Tap to Add Address 3"
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {hasUserChanges && (
              <button className="save-btn" onClick={handleSaveChanges}>
                Save Changes
              </button>
            )}
          </div>
        );
      case "bookingDetails":
        return (
          <div className="booking-overview-section">
            <h2>Your Bookings</h2>
            <div className="booking-date-selection">
              <input
                type="date"
                value={chosenDate}
                onChange={(e) => handleDateSelection(e.target.value)}
              />
            </div>
            <div className="booking-list">
              {bookingDetails[chosenDate] &&
                bookingDetails[chosenDate].map((booking, index) => (
                  <div className="booking-item" key={index}>
                    <div className="booking-item-left">
                      <h4>{booking.serviceName}</h4>
                      <p>
                        {booking.providerName} - {booking.time} -{" "}
                        {booking.duration}
                      </p>
                    </div>
                    <div className="booking-item-right">
                      <p className="booking-price">${booking.price}</p>
                      <p className="booking-location">{booking.location}</p>
                      <p
                        className={`booking-status ${
                          booking.status === "Confirmed"
                            ? "status-confirmed"
                            : "status-pending"
                        }`}
                      >
                        {booking.status === "Confirmed" ? (
                          <>
                            <span className="status-dot confirmed-dot"></span>
                            Confirmed
                          </>
                        ) : (
                          <>
                            <span className="status-dot pending-dot"></span>
                            Pending
                          </>
                        )}
                      </p>
                    </div>
                  </div>
                ))}
            </div>
          </div>
        );
      case "languagePreferences":
        return (
          <div className="language-preference-section">
            <h2>Language Preferences</h2>
            <button className="open-modal-btn" onClick={toggleLanguageModal}>
              Change Language
            </button>
            <Modal
              isOpen={isLanguageModalOpen}
              onRequestClose={closeLanguageModal}
              contentLabel="Language Selection Modal"
              className="modal-content"
              overlayClassName="modal-overlay"
            >
              <h2>Select Language</h2>
              <ul className="language-list">
                <li>
                  <label className="language-option">
                    <input type="radio" name="language" value="en" />
                    English
                  </label>
                </li>
                <li>
                  <label className="language-option">
                    <input type="radio" name="language" value="hi" />
                    Hindi
                  </label>
                </li>
              </ul>
              <button className="modal-close-btn" onClick={closeLanguageModal}>
                Continue
              </button>
            </Modal>
          </div>
        );
      //   case "faqSection":
      //     return <FAQSection />;
      default:
        return null;
    }
  };

  return (
    <div className="user-dashboard">
      <div className="dashboard-header">
        <h1>
          {greetingMessage} {userDetails.fullName}
        </h1>
      </div>
      <div className="dashboard-container">
        <div className="sidebar">
          <ul>
            <li
              className={currentTab === "userProfile" ? "active" : ""}
              onClick={() => setCurrentTab("userProfile")}
            >
              <FaUser className="sidebar-icon" />
              Profile
            </li>
            <li
              className={currentTab === "bookingDetails" ? "active" : ""}
              onClick={() => setCurrentTab("bookingDetails")}
            >
              <FaCalendarAlt className="sidebar-icon" />
              Bookings
            </li>
            <li
              className={currentTab === "languagePreferences" ? "active" : ""}
              onClick={() => setCurrentTab("languagePreferences")}
            >
              <FaLanguage className="sidebar-icon" />
              App Language
            </li>
            <li
              className={currentTab === "faqSection" ? "active" : ""}
              onClick={() => setCurrentTab("faqSection")}
            >
              <FaQuestionCircle className="sidebar-icon" />
              FAQ
            </li>
            <li onClick={handleLogoutClick}>
              <FaSignOutAlt className="sidebar-icon" />
              Logout
            </li>
          </ul>
        </div>
        <div className="content">{renderTabContent()}</div>
      </div>
      {isAddressModalVisible && (
        <AddressPopup
          isOpen={isAddressModalVisible}
          onRequestClose={closeAddressModal}
          onSave={handleAddressSave}
          currentField={currentAddressField}
        />
      )}
    </div>
  );
};

export default ServiceProfile;
