import React from "react";
import "./particular-card.css";

const ParticularCard = ({ cards }) => {
  return (
    <div className="particular-card">
      <div className="particular-card-container">
        {cards.map((card, index) => (
          <div className="particular-service-card">
            <h2>{card.name}</h2>
            <div
              className="particular-card-item"
              key={index}
              style={{ backgroundImage: `url(${card.image})` }}
            >
              <h3>{card.heading}</h3>
              <h6>{card.heading2}</h6>
              {/* <p>{card.description}</p> */}
              <div className="particular-card-btn-group">
                {/* <button
                className="particular-card-btn2"
                onClick={card.btn2Action}
              >
                {card.btn2Text}
              </button> */}
                <button
                  className="particular-card-btn1"
                  onClick={card.btn1Action}
                >
                  {card.btn1Text}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ParticularCard;
