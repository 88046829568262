import React from 'react';
import './marquee.css';

const Marquee = () => {
  return (
    <div className="marquee-container">
      <div className="marquee-text">Exciting things are coming—stay tuned for our upcoming services!</div>
      <hr className="marquee-line" />
    </div>
  );
}

export default Marquee;
