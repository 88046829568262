import React, { useState } from "react";
import "./SecondaryNavbar.css";
import personal1 from "../Assets/personal1.png";
import personal2 from "../Assets/personal2.png";
import personal3 from "../Assets/personal3.png";
import { Link } from "react-router-dom";
import homemaintainance1 from "../Assets/plumber.png";
import homemaintainance2 from "../Assets/plug.png";
import homemaintainance3 from "../Assets/helmet.png";
import homemaintainance4 from "../Assets/hammer.png";
import homemaintainance5 from "../Assets/interior.png";
import homemaintainance6 from "../Assets/fence.png";
import homemaintainance7 from "../Assets/architect.png";
import homemaintainance8 from "../Assets/window.png";
import educational1 from "../Assets/tutoring.png";
import educational2 from "../Assets/mentor.png";
import educational3 from "../Assets/tutor.png";
import health1 from "../Assets/coach.png";
import health2 from "../Assets/yoga-pose.png";
import health3 from "../Assets/yoga.png";
import health4 from "../Assets/consultation.png";
import household1 from "../Assets/relocation.png";
import household2 from "../Assets/baby-girl.png";
import household3 from "../Assets/rooms.png";
import professional1 from "../Assets/bullhorn.png";
import professional2 from "../Assets/advisor.png";
import professional3 from "../Assets/taxes.png";
import professional4 from "../Assets/coding.png";
import event1 from "../Assets/tent.png";
import event2 from "../Assets/ballerina.png";
import event3 from "../Assets/photographer.png";
import event4 from "../Assets/calendar.png";
import event5 from "../Assets/dj.png";
import food1 from "../Assets/tanker-truck.png";
import food2 from "../Assets/food-preparation.png";
import food3 from "../Assets/brush.png";
import transport1 from "../Assets/taxi.png";
import transport2 from "../Assets/transport.png";
import transport3 from "../Assets/hotel.png";
import transport4 from "../Assets/travel-map.png";
const SecondaryNavbar = () => {
  const [visible, setVisible] = useState(null);

  const handleMouseEnter = (item) => {
    setVisible(item);
  };

  const handleMouseLeave = () => {
    setVisible(null);
  };

  return (
    <div className="secondary-navbar-container">
      <nav className="secondary-navbar">
        <ul className="nav-links">
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("personal")}
            onMouseLeave={handleMouseLeave}
          >
            Personal
            {visible === "personal" && (
              <div className="dropdown-content">
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={personal1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=beauty"
                      className="card-button-nav2"
                    >
                      Beauticians Services
                    </Link>
                  </div>
                  {/* <div className="card-nav2">
                    <img
                      src={personal2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=fashion"
                      className="card-button-nav2"
                    >
                      Fashion Advice
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={personal3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=tailoringServices"
                      className="card-button-nav2"
                    >
                      Tailoring Services
                    </Link>
                  </div> */}
                </div>
              </div>
            )}
          </li>
          {/* <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("homemaintainance")}
            onMouseLeave={handleMouseLeave}
          >
            Maintenance
            {visible === "homemaintainance" && (
              <div className="dropdown-content" style={{ left: "-117px" }}>
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={homemaintainance1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=plumbingServices"
                      className="card-button-nav2"
                    >
                      Plumbing Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=electricianServices"
                      className="card-button-nav2"
                    >
                      Electrician Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=masonServices"
                      className="card-button-nav2"
                    >
                      Mason Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance4}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=carpenterServices"
                      className="card-button-nav2"
                    >
                      Carpenter Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance5}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=interiorDesigning"
                      className="card-button-nav2"
                    >
                      Interior Designing
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance6}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=exteriorDesigning"
                      className="card-button-nav2"
                    >
                      Exterior Designing
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance7}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=architectServices"
                      className="card-button-nav2"
                    >
                      Architect Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={homemaintainance8}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=glassWork"
                      className="card-button-nav2"
                    >
                      Glass Works
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("educational")}
            onMouseLeave={handleMouseLeave}
          >
            Educational
            {visible === "educational" && (
              <div className="dropdown-content">
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={educational1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=homeTutor"
                      className="card-button-nav2"
                    >
                      Home Tutor
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={educational2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=tutionCenter"
                      className="card-button-nav2"
                    >
                      Tution Center
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={educational3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=onlineTution"
                      className="card-button-nav2"
                    >
                      Online Tution
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("household")}
            onMouseLeave={handleMouseLeave}
          >
            Household
            {visible === "household" && (
              <div className="dropdown-content">
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={household1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=houseShifting"
                      className="card-button-nav2"
                    >
                      House Shifting
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={household2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=babySitting"
                      className="card-button-nav2"
                    >
                      Baby Sitting
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={household3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=roomforRent"
                      className="card-button-nav2"
                    >
                      Room for Rent
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("healthandwellness")}
            onMouseLeave={handleMouseLeave}
          >
            Health and Wellness
            {visible === "healthandwellness" && (
              <div className="dropdown-content">
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={health1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=sportsCoaching"
                      className="card-button-nav2"
                    >
                      Sports Coaching
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={health2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=offlineYogaClass"
                      className="card-button-nav2"
                    >
                      Offline Yoga Class
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={health3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=offlineYogaClass"
                      className="card-button-nav2"
                    >
                      Online Yoga Class
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={health4}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=fitnessConsultation"
                      className="card-button-nav2"
                    >
                      Fitness Consultation
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("professinalservices")}
            onMouseLeave={handleMouseLeave}
          >
            Professional Services
            {visible === "professinalservices" && (
              <div className="dropdown-content">
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={professional1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=digitalMarketing"
                      className="card-button-nav2"
                    >
                      Digital Marketing
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={professional2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=careerCounselling"
                      className="card-button-nav2"
                    >
                      Career Counselling
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={professional3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=CA"
                      className="card-button-nav2"
                    >
                      CA
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={professional4}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=webDevelopment"
                      className="card-button-nav2"
                    >
                      Web Development
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("eventandentertainment")}
            onMouseLeave={handleMouseLeave}
          >
            Event and Entertainment
            {visible === "eventandentertainment" && (
              <div className="dropdown-content " style={{ left: "-364px" }}>
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={event1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=tentForEvents"
                      className="card-button-nav2"
                    >
                      Tent for Events
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={event2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=danceClasses"
                      className="card-button-nav2"
                    >
                      Dance Classes
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={event3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=photographer"
                      className="card-button-nav2"
                    >
                      Photographer
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={event4}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=eventOrganiser"
                      className="card-button-nav2"
                    >
                      Event Organizer
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={event5}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=bookDj"
                      className="card-button-nav2"
                    >
                      Book DJ
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li> */}
          {/* <li
            className="nav-item"
            onMouseEnter={() => handleMouseEnter("foodandbeverages")}
            onMouseLeave={handleMouseLeave}
          >
            Food and Beverages
            {visible === "foodandbeverages" && (
              <div className="dropdown-content">
               
                <div className="card-container">
                  <div className="card">Product Card 1</div>
                  <div className="card">Product Card 2</div>
                  <div className="card">Product Card 3</div>
                </div>
              </div>
            )}
          </li> */}
          {/* <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("transportation")}
            onMouseLeave={handleMouseLeave}
          >
            Transportation
            {visible === "transportation" && (
              <div className="dropdown-content" style={{ left: "-400px" }}>
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={transport1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=taxiBooking"
                      className="card-button-nav2"
                    >
                      Taxi Booking
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={transport2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=busBooking"
                      className="card-button-nav2"
                    >
                      Bus Booking
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={transport3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=hotelBooking"
                      className="card-button-nav2"
                    >
                      Hotel Booking
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={transport4}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=tourAndTravel"
                      className="card-button-nav2"
                    >
                      Tour and Travel
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li>
          <li
            className="nav-item2"
            onMouseEnter={() => handleMouseEnter("foodandwaterbeverages")}
            onMouseLeave={handleMouseLeave}
          >
            Food and Water Beverages
            {visible === "foodandwaterbeverages" && (
              <div className="dropdown-content" style={{ left: "-290px" }}>
                <div className="card-container-nav2">
                  <div className="card-nav2">
                    <img
                      src={food1}
                      alt="Service 1"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=waterTanker"
                      className="card-button-nav2"
                    >
                      Water Tanker
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={food2}
                      alt="Service 2"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=cookingServices"
                      className="card-button-nav2"
                    >
                      Cooking Services
                    </Link>
                  </div>
                  <div className="card-nav2">
                    <img
                      src={food3}
                      alt="Service 3"
                      className="card-image-nav2"
                    />
                    <Link
                      to="/particular-service?category=cleanWaterTank"
                      className="card-button-nav2"
                    >
                      Clean Water Tank
                    </Link>
                  </div>
                </div>
              </div>
            )}
          </li> */}
        </ul>
      </nav>
    </div>
  );
};

export default SecondaryNavbar;
