import React, { useState, useEffect, useRef } from "react";
import { FaChevronLeft, FaChevronRight } from "react-icons/fa";
import "./card-carousel.css";

const CardCarousel = ({ cards }) => {
  const carouselRef = useRef(null);
  const [scrollInterval, setScrollInterval] = useState(null);

  useEffect(() => {
    const container = carouselRef.current;
    if (container) {
      const scrollWidth = container.scrollWidth;
      const containerWidth = container.clientWidth;

      // Set up automatic scrolling
      const interval = setInterval(() => {
        container.scrollBy({ left: 200, behavior: "smooth" });
        if (container.scrollLeft + containerWidth >= scrollWidth) {
          container.scrollLeft = 0;
        }
      }, 3500);

      setScrollInterval(interval);

      return () => clearInterval(interval);
    }
  }, [cards]);

  const handlePrev = () => {
    carouselRef.current.scrollBy({ left: -400, behavior: "smooth" });
  };

  const handleNext = () => {
    carouselRef.current.scrollBy({ left: 400, behavior: "smooth" });
  };

  return (
    <div className="card-carousel-wrapper">
      <button className="nav-btn prev" onClick={handlePrev}>
        <FaChevronLeft />
      </button>
      <div className="card-carousel" ref={carouselRef}>
        <div className="card-container">
          {cards.map((card, index) => (
            <div
              className="card-service-carousel"
              key={index}
              style={{ backgroundImage: `url(${card.image})` }}
            >
              <div className="card-content">
                <h3>{card.heading}</h3>
                <p>{card.description}</p>
                <div className="btn-group">
                  <button className="btn2" onClick={card.btn2Action}>
                    {card.btn2Text}
                  </button>
                  <button className="btn-card1" onClick={card.btn1Action}>
                    {card.btn1Text}
                  </button>
                </div>
              </div>
            </div>
          ))}
          {cards.map((card, index) => (
            <div
              className="card-service-carousel"
              key={`loop-${index}`}
              style={{ backgroundImage: `url(${card.image})` }}
            >
              <div className="card-content">
                <h3>{card.heading}</h3>
                <p>{card.description}</p>
                <div className="btn-group">
                  <button className="btn2" onClick={card.btn2Action}>
                    {card.btn2Text}
                  </button>
                  <button className="btn-card1" onClick={card.btn1Action}>
                    {card.btn1Text}
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
      <button className="nav-btn next" onClick={handleNext}>
        <FaChevronRight />
      </button>
    </div>
  );
};

export default CardCarousel;
