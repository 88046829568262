import React from "react";
import "./overall-card.css";

const OverallCard = ({ cards }) => {
  return (
    <div className="overall-card">
  <div className="overall-card-container">
    {cards.map((card, index) => (
      <div
        className="overall-card-item"
        key={index}
        style={{ backgroundImage: `url(${card.image})` }}
      >
        <h3>{card.heading}</h3>
        <p>{card.description}</p>
        <div className="overall-card-btn-group">
        <button className="overall-card-btn2" onClick={card.btn2Action}>
            {card.btn2Text}
          </button>
          <button className="overall-card-btn1" onClick={card.btn1Action}>
            {card.btn1Text}
          </button>
          
        </div>
      </div>
    ))}
  </div>
</div>

  );
};

export default OverallCard;
