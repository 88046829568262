import React, { useState } from "react";
import "./FAQSection.css"; // Make sure to include the CSS file

const FAQSection = () => {
  const [activeIndex, setActiveIndex] = useState(null);

  const faqs = [
    {
      question: "What is RAPS APP?",
      answer: "You can return items within 30 days of purchase.",
    },
    {
      question: "How do I book a service?",
      answer:
        "You can track your order using the tracking link provided in your confirmation email.",
    },
    {
      question: "What types of services does RAPS APP offer?",
      answer:
        "We offer a variety of services including home cleaning, plumbing, beauty services, electrical repairs, tech support, pest control, and carpentry, among others.",
    },
    // Add more FAQs as needed
  ];

  const handleToggle = (index) => {
    setActiveIndex(activeIndex === index ? null : index);
  };

  return (
    <div className="faq-section">
      <h2>FAQs</h2>
      <table className="faq-table">
        <thead>
          <tr>
            <th style={{ paddingRight: "40px" }}>S.No</th>
            <th>Questions</th>
          </tr>
        </thead>
        <tbody>
          {faqs.map((faq, index) => (
            <React.Fragment key={index}>
              <tr
                onClick={() => handleToggle(index)}
                className={`faq-row ${activeIndex === index ? "active" : ""}`}
              >
                <td className="faq-sno">
                  <div>{index + 1}</div>
                </td>
                <td className="faq-question">{faq.question}</td>
              </tr>
              {activeIndex === index && (
                <tr className="faq-answer">
                  <td colSpan="2">{faq.answer}</td>
                </tr>
              )}
            </React.Fragment>
          ))}
        </tbody>
      </table>
    </div>
  );
};

export default FAQSection;
